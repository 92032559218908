.development-banner {
   background: linear-gradient(45deg, #ff6b6b, #feca57);
   color: white;
   text-align: center;
   padding: 10px;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 45px;
   z-index: 9999;
   transition: top 0.3s ease, box-shadow 0.3s ease;
   font-family: 'Arial', sans-serif;
   font-weight: bold;
   text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  
   &.floating {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
   }
  
   .dev {
      color: red;
      font-weight: bold;
      font-size: 1.2em;
   }
  
   .prod {
      color: green;
      font-weight: bold;
      font-size: 1.2em;
   }
}