.blog-post-container {
    max-width: 800px;
    margin: 0 auto;
    padding-top: 200px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: Arial, sans-serif;
  
    .blog-post-title {
      font-size: 2.5rem;
      color: #bd692d;
      margin-bottom: 10px;
    }
  
    .blog-post-date {
      font-size: 1rem;
      color: #666;
      margin-bottom: 30px;
    }
  
    .blog-post-content {
      h2 {
        font-size: 1.8rem;
        color: #23283b;
        margin-top: 30px;
        margin-bottom: 15px;
      }
  
      p {
        font-size: 1.1rem;
        line-height: 1.6;
        margin-bottom: 15px;
      }
  
      ol, ul {
        margin-bottom: 20px;
        padding-left: 20px;
  
        li {
          font-size: 1.1rem;
          line-height: 1.6;
          margin-bottom: 10px;
        }
      }
  
      .image-container {
        margin-bottom: 20px;
        text-align: center;
  
        &.breeder-map-preview {
          .image-wrapper {
            position: relative;
            display: inline-block;
            cursor: pointer;
  
            .blog-image {
              max-width: 100%;
              height: auto;
            }
  
            .image-overlay {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.5);
              display: flex;
              justify-content: center;
              align-items: center;
              opacity: 0;
              transition: opacity 0.3s ease;
  
              span {
                color: white;
                font-size: 18px;
                font-weight: bold;
              }
            }
  
            &:hover .image-overlay {
              opacity: 1;
            }
          }
        }
      }
  
      .image-description {
        font-size: 0.9rem;
        color: #666;
        text-align: center;
        margin-bottom: 20px;
      }
  
      .cta-section {
        background-color: #f5f5f5;
        padding: 20px;
        border-radius: 5px;
        margin-top: 30px;
        text-align: center;
  
        p {
          font-size: 1.2rem;
          margin-bottom: 15px;
        }
  
        .cta-button {
          display: inline-block;
          background-color: #bd692d;
          color: white;
          padding: 10px 20px;
          border-radius: 5px;
          text-decoration: none;
          font-weight: bold;
          transition: background-color 0.3s ease;
  
          &:hover {
            background-color: darken(#bd692d, 10%);
          }
        }
      }
  
      .feedback-section {
        margin-top: 30px;
        padding: 20px;
        background-color: #f0f2f5;
        border-radius: 5px;
  
        h2 {
          color: #23283b;
          font-size: 1.5rem;
          margin-bottom: 10px;
        }
  
        p {
          font-size: 1rem;
          line-height: 1.5;
        }
      }
    }
  
    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
  
      .modal-content {
        position: relative;
        max-width: 90%;
        max-height: 90%;
  
        .modal-image-container {
          display: flex;
          justify-content: center;
          align-items: center;
        }
  
        .modal-image {
          max-width: 100%;
          max-height: 90vh;
          object-fit: contain;
        }
  
        .close-button {
          position: absolute;
          top: 10px;
          right: 10px;
          background-color: rgba(0, 0, 0, 0.5);
          color: white;
          border: none;
          font-size: 24px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: background-color 0.3s ease;
  
          &:hover {
            background-color: rgba(0, 0, 0, 0.8);
          }
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .blog-post-container {
      padding-top: 150px;
      padding-bottom: 30px;
      padding-left: 15px;
      padding-right: 15px;
  
      .blog-post-title {
        font-size: 2rem;
      }
  
      .blog-post-content {
        h2 {
          font-size: 1.5rem;
        }
  
        p, ol li, ul li {
          font-size: 1rem;
        }
      }
    }
  }