@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

.blogs-container {
  background-color: white;
  padding-top: 200px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #23283b;
  position: relative;
  z-index: 1;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 800'%3E%3Cg fill-opacity='0.3'%3E%3Cpath fill='%2323283b' d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath fill='%23343b56' d='M-31 229L237 261 390 382 603 494 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath fill='%23454e71' d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath fill='%2357628c' d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath fill='%236976a7' d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
    opacity: 0.25;
    z-index: -1;
    animation: rotateBrushStroke 60s linear infinite;
  }
}

.blogs-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.blogs-title {
  font-size: 2.5rem;
  color: #bd692d;
  margin-bottom: 30px;
  text-align: center;
}

.blog-posts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.blog-post-card {
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  .blog-post-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .blog-post-content {
    padding: 20px;
  }

  .blog-post-title {
    font-size: 1.5rem;
    color: #23283b;
    margin-bottom: 10px;
  }

  .blog-post-excerpt {
    color: #666;
    margin-bottom: 15px;
  }

  .read-more-link {
    display: inline-block;
    color: #bd692d;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;

    &:hover {
      color: darken(#bd692d, 10%);
    }
  }
}

@media (max-width: 1200px) {
  .blogs-container {
    padding-top: 200px;
  }
}

@media (max-width: 768px) {
  .blogs-container {
    padding-top: 150px;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .blogs-title {
    font-size: 2rem;
  }

  .blog-posts {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .blogs-container {
    padding-top: 140px;
  }
}

@keyframes rotateBrushStroke {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.blog-post-meta {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}

.blog-post-date {
  font-size: 0.9rem;
  color: #666;
}

.read-more-link {
  display: inline-block;
  color: #bd692d;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;

  &:hover {
    color: darken(#bd692d, 10%);
  }
}