@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

.learn-container {
  background-color: white;
  padding: 210px 50px 100px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #23283b;
  position: relative;
  z-index: 1;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 800'%3E%3Cg fill-opacity='0.3'%3E%3Cpath fill='%2323283b' d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath fill='%23343b56' d='M-31 229L237 261 390 382 603 494 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath fill='%23454e71' d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath fill='%2357628c' d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath fill='%236976a7' d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
    opacity: 0.25;
    z-index: -1;
    animation: rotateBrushStroke 60s linear infinite;
  }

  @media (max-width: 1200px) {
    padding-top: 200px;
  }

  @media (max-width: 768px) {
    padding-top: 180px;
  }

  @media (max-width: 480px) {
    padding-top: 140px;
  }
}

@keyframes rotateBrushStroke {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.learn-header {
  text-align: center;
  margin-bottom: 50px;
  width: 100%;
  max-width: 1200px;
  
  .learn-title {
    font-size: 42px;
    color: #2c3e50;
    margin-bottom: 15px;
    position: relative;
    display: inline-block;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 60px;
      height: 4px;
      background-color: #e67e22;
    }

    @media (max-width: 768px) {
      font-size: 32px;
    }

    @media (max-width: 480px) {
      font-size: 28px;
    }
  }

  .learn-subtitle {
    font-size: 18px;
    color: #7f8c8d;
    max-width: 700px;
    margin: 20px auto 0;

    @media (max-width: 768px) {
      font-size: 16px;
    }

    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
}

.species-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-bottom: 50px;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 20px;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
}

.species-card {
  background-color: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);

    .gecko-image {
      transform: scale(1.1);
    }
  }
}

.image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;

  @media (max-width: 480px) {
    height: 180px;
  }
}

.gecko-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.species-info {
  padding: 20px;
  text-align: center;

  h3 {
    margin: 0 0 10px;
    color: #2c3e50;
    font-size: 24px;

    @media (max-width: 768px) {
      font-size: 22px;
    }

    @media (max-width: 480px) {
      font-size: 20px;
    }
  }

  .scientific-name {
    color: #7f8c8d;
    font-style: italic;
    margin-bottom: 15px;
    font-size: 14px;

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
}

.learn-more-btn {
  background-color: #e67e22;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-decoration: none;
  display: inline-block;

  &:hover {
    background-color: #d35400;
    transform: scale(1.05);
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 8px 16px;
  }
}