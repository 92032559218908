// src/components/Auth/SignUp.scss
.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding-top: 200px;
    padding-bottom: 20px;
  }
  
  .signup-form {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  
    h2 {
      color: #23283b;
      margin-bottom: 1.5rem;
      text-align: center;
      font-size: 1.5rem;
    }
  
    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  
    .form-group {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
  
      label {
        font-size: 0.9rem;
        color: #666;
      }
  
      input {
        padding: 0.75rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 1rem;
  
        &:focus {
          outline: none;
          border-color: #bd692d;
        }
      }
    }
  
    button {
      background-color: #bd692d;
      color: white;
      padding: 0.75rem;
      border: none;
      border-radius: 4px;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: darken(#bd692d, 10%);
      }
  
      &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
    }
  
    .login-link {
      margin-top: 1rem;
      text-align: center;
      font-size: 0.9rem;
      color: #666;
  
      a {
        color: #bd692d;
        text-decoration: none;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }