.login-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10001;
  animation: fadeIn 0.3s ease-out;
}

.login-popup {
  background-color: #fff;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 90%;
  position: relative;
  animation: popIn 0.3s ease-out;

  h2 {
    color: #23283b;
    margin-bottom: 1rem;
    font-size: 1.75rem;
    text-align: center;
    font-weight: 600;
  }

  p {
    color: #666;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 1.1rem;
  }

  .login-options {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .signin-button,
    .signup-button,
    .guest-button,
    .google-signin-button {
      padding: 0.875rem;
      border: 1px solid; // Ensure all buttons have a border
      border-radius: 6px;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      svg {
        margin-right: 0.75rem;
        font-size: 1.25rem;
      }
    }

    .signin-button {
      background-color: #bd692d;
      color: white;
      border-color: #bd692d;

      &:hover {
        background-color: darken(#bd692d, 10%);
        border-color: darken(#bd692d, 10%);
      }
    }

    .signup-button {
      background-color: #23283b;
      color: white;
      border-color: #23283b;

      &:hover {
        background-color: darken(#23283b, 10%);
        border-color: darken(#23283b, 10%);
      }
    }

    .guest-button {
      background-color: #f0f0f0;
      color: #333;
      border-color: #ccc;

      &:hover {
        background-color: darken(#f0f0f0, 10%);
        border-color: #999;
      }
    }

    // Ensure Google Sign-In button styles are consistent
    .google-signin-button {
      background-color: #ffffff;
      color: #757575;
      border-color: #dadce0;

      &:hover {
        background-color: #f8f9fa;
        border-color: #4285F4;
        box-shadow: 0 1px 3px rgba(60,64,67,0.3);
      }
    }
  }

  .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
    transition: color 0.3s ease;

    &:hover {
      color: #333;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes popIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}