.filter-container {
  width: 100%;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 2rem;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
  }
}

.filter-title {
  font-size: 1.75rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1.5rem;
  color: #23283b;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.clear-filters-btn {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  background-color: #fff;
  border: 2px solid #23283b;
  color: #23283b;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9rem;

  &:hover {
    background-color: #23283b;
    color: #fff;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
}

.filter-accordion {
  border: 1px solid #dee2e6;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;

  &:hover {
    border-color: #23283b;
  }
}

@media (max-width: 768px) {
  .filter-container {
    margin-bottom: 1rem;
  }
}