@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

body {
  background-color: #23283b;
  transition: background-color 1s ease;
  font-family: 'Poppins', Arial, sans-serif;
}

.navbar {
  background-color: #23283b;
  transition: background-color 2s ease;
  position: fixed;
  top: 45px;
  left: 0;
  width: 100%;
  z-index: 10000;
}

.hero-section {
  background-color: #23283b;
  padding: 140px 50px 100px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
  z-index: 1;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 800'%3E%3Cg fill-opacity='0.2'%3E%3Cpath fill='%23bd692d' d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath fill='%23eb8e04' d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath fill='%23fbbc48' d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath fill='%23ff6b6b' d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath fill='%23bd692d' d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
    opacity: 0.2;
    z-index: -1;
    animation: rotateBrushStroke 60s linear infinite;
  }

  .hero-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }

  .hero-content {
    width: 100%;
    max-width: 600px;
    text-align: center;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    min-height: 300px;

    .hero-text-container {
      position: relative;
      width: 100%;
      height: 300px;
      overflow: hidden;
    }

    .hero-text {
      transition: transform 0.5s ease;
      padding: 30px;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }

    .hero-title {
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 1rem;
      background: linear-gradient(45deg, #ff6b6b, #eb8e04, #fbbc48);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    .hero-subtitle {
      font-size: 1.5rem;
      margin-bottom: 2rem;
      color: #f0f0f0;
    }

    .hero-cta {
      @extend .button;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  .image-showcase {
    width: 100%;
    max-width: 600px;
    aspect-ratio: 4 / 3;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: none;
  }

  @media (min-width: 320px) {
    padding-top: 120px;
  }

  @media (min-width: 1200px) {
    .hero-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .hero-content {
      width: 50%;
      text-align: left;
      margin-bottom: 0;
      padding-right: 20px;
    }

    .image-showcase {
      width: 50%;
    }
    padding-top: 140px;
  }

  @media (max-width: 768px) {
    padding: 120px 20px 60px;

    .hero-title {
      font-size: 2rem;
    }

    .hero-subtitle {
      font-size: 1rem;
    }
    .hero-text-container {
      height: 250px;
    }
  }

  @media (max-width: 480px) {
    padding: 120px 20px 40px;

    .hero-title {
      font-size: 1.5rem;
    }

    .hero-subtitle {
      font-size: 0.9rem;
    }
    .hero-text-container {
      height: 200px;
    }
  }
}

@keyframes rotateBrushStroke {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.image-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: fadeInOut 15s infinite;

  &:nth-child(1) {
    animation-delay: 0s;
  }

  &:nth-child(2) {
    animation-delay: 5s;
  }

  &:nth-child(3) {
    animation-delay: 10s;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

@keyframes fadeInOut {
  0%, 45%, 100% { opacity: 0; transform: scale(1.1); }
  10%, 35% { opacity: 1; transform: scale(1); }
}

.diagonal-transition {
  height: 100px;
  background: linear-gradient(to bottom right, #23283b 49.9%, #ffffff 50.1%);
  position: relative;
  z-index: 2;
  margin-top: -50px;
  margin-bottom: -50px;

  &.reverse {
    background: linear-gradient(to bottom left, #ffffff 49.9%, #23283b 50.1%);
  }
}

@media (max-width: 768px) {
  .diagonal-transition {
    height: 50px;
    margin-top: -25px;
    margin-bottom: -25px;
  }
}

@media (max-width: 480px) {
  .diagonal-transition {
    height: 30px;
    margin-top: -15px;
    margin-bottom: -15px;
  }
}

.second-section, .fourth-section {
  background-color: #ffffff;
  padding: 80px 50px 50px;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;

  .section-title {
    font-size: 3rem;
    margin-bottom: 40px;
    color: #23283b;
    font-weight: 700;
  }

  .content-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
  }
}

.second-section {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 800'%3E%3Cg fill-opacity='0.3'%3E%3Cpath fill='%2323283b' d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath fill='%23343b56' d='M-31 229L237 261 390 382 603 494 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath fill='%23454e71' d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath fill='%2357628c' d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath fill='%236976a7' d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
    opacity: 0.25;
    z-index: -1;
    animation: rotateBrushStroke 60s linear infinite;
  }

  .image-gallery {
    flex: 1;
    max-width: 600px;
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-image-container {
      width: 100%;
      height: 400px;
      overflow: hidden;
      position: relative;
      border-radius: 15px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
      background-color: #f0f0f0;

      .main-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .thumbnail-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 20px;
      margin-bottom: 25px;

      .thumbnail {
        width: 80px;
        height: 80px;
        object-fit: cover;
        margin: 5px;
        cursor: pointer;
        border: 2px solid transparent;
        border-radius: 8px;
        transition: all 0.3s ease;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        &:hover {
          transform: translateY(-5px);
        }

        &.active {
          border-color: #bd692d;
          box-shadow: 0 5px 15px rgba(189, 105, 45, 0.3);
        }
      }
    }
  }

  .species-info {
    flex: 1;
    text-align: left;
    max-width: 600px;
    margin-left: 20px;
    background-color: #f8f8f8;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      text-align: center;
      font-size: 2.2rem;
      margin-bottom: 20px;
      color: #23283b;
      font-weight: 700;
    }

    .description {
      text-align: center;
      font-style: italic;
      margin-bottom: 30px;
      font-size: 1.1rem;
      color: #555;
      line-height: 1.6;
    }

    ul {
      list-style-type: none;
      padding: 0;
      columns: 2;
      column-gap: 30px;

      li {
        margin-bottom: 15px;
        font-size: 1rem;
        color: #444;

        strong {
          margin-right: 5px;
          color: #23283b;
          font-weight: 600;
        }
      }
    }

    .learn-more-button {
      @extend .button;
      margin-top: 20px;
      text-align: center;
      width: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .second-section {
    .image-gallery, .species-info {
      width: 100%;
      max-width: 100%; 
      margin: 0 0 40px 0; 
    }

    .image-gallery {
      .main-image-container {
        height: 300px; 
      }
    }
  }
}

@media (max-width: 768px) {
  .second-section {
    .image-gallery {
      .main-image-container {
        height: 300px;
      }
    }
  }
}

@media (max-width: 480px) {
  .second-section {
    .image-gallery {
      .main-image-container {
        height: 250px;
      }
    }
  }
}

.third-section {
  background-color: #23283b;
  padding: 80px 50px 50px;
  text-align: center;
  position: relative;
  z-index: 1;
  color: white;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 800'%3E%3Cg fill-opacity='0.2'%3E%3Cpath fill='%23bd692d' d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath fill='%23eb8e04' d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath fill='%23fbbc48' d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath fill='%23ff6b6b' d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath fill='%23bd692d' d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
    opacity: 0.2;
    z-index: -1;
    animation: rotateBrushStroke 60s linear infinite;
  }

  .section-title {
    font-size: 3rem;
    margin-bottom: 40px;
    color: white;
    font-weight: 700;
  }

  .content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .gecko-slider {
    width: 100%;
    margin-bottom: 40px;
    position: relative;

    .slick-slide {
      padding: 0 10px;
    }

    .slick-prev,
    .slick-next {
      z-index: 1;
      width: 50px;
      height: 50px;
      &:before {
        color: #bd692d;
        font-size: 50px;
      }
    }

    .slick-prev {
      left: -60px;
    }

    .slick-next {
      right: -60px;
    }

    .slick-dots {
      bottom: -30px;

      li {
        button:before {
          
          color: #bd692d;
          opacity: 0.25;
        }

        &.slick-active button:before {
          color: #bd692d;
          opacity: 0.75;
        }
      }
    }

    @media (max-width: 1300px) {
      .slick-prev {
        left: -40px;
      }
      .slick-next {
        right: -40px;
      }
    }

    @media (max-width: 1100px) {
      .slick-prev,
      .slick-next {
        width: 40px;
        height: 40px;
        &:before {
          font-size: 40px;
        }
      }
      .slick-prev {
        left: -30px;
      }
      .slick-next {
        right: -30px;
      }
    }

    @media (max-width: 768px) {
      .slick-prev,
      .slick-next {
        width: 30px;
        height: 30px;
        &:before {
          font-size: 30px;
        }
      }
      .slick-prev {
        left: -15px;
      }
      .slick-next {
        right: -15px;
      }
    }
  }

  .gecko-slider-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
    }

    .card-image-container {
      position: relative;
      padding-top: 100%;
      overflow: hidden;

      .card-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .card-quick-info {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(35, 40, 59, 0.8);
        color: #fff;
        display: flex;
        justify-content: space-around;
        padding: 5px;
        font-size: 12px;
      }
    }

    .card-content {
      padding: 15px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .card-info {
      flex-grow: 1;
    }

    .card-title {
      margin: 0 0 10px;
      color: #23283b;
      line-height: 1.2;
      height: 2.4em;
      overflow: hidden;
      display: flex;
      align-items: center;
    }

    .card-title-text {
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .card-price {
      font-size: 16px;
      font-weight: bold;
      color: #23283b;
      margin-bottom: 10px;
    }

    .card-actions {
      display: flex;
      gap: 10px;
      margin-top: auto;

      .btn {
        flex: 1;
        padding: 8px;
        font-size: 14px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        white-space: nowrap;

        &.btn-primary {
          background-color: #23283b;
          color: white;

          &:hover {
            background-color: #1a1f2e;
          }
        }

        &.btn-secondary {
          background-color: #bd692d;
          color: white;

          &:hover {
            background-color: #a55a25;
          }

          &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
          }
        }
      }
    }

    .card-status {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px 10px;
      border-radius: 20px;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;

      &.available {
        background-color: #28a745;
        color: #fff;
      }

      &.sold {
        background-color: #dc3545;
        color: #fff;
      }

      &.reserved {
        background-color: #ffc107;
        color: #23283b;
      }
    }
  }

  .view-all-btn {
    @extend .button;
    margin-top: 40px;
  }
}
.fourth-section {
  background-color: #f8f8f8;
  padding: 80px 50px;

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 40px;
  }

  .calculator-preview {
    flex: 1;
    background-color: #ffffff;
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-5px);
    }

    .calculator-icon {
      font-size: 4rem;
      color: #bd692d;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 2.2rem;
      color: #23283b;
      margin-bottom: 15px;
    }

    p {
      font-size: 1.1rem;
      color: #555;
      margin-bottom: 30px;
    }

    .calculator-btn {
      @extend .button;
    }
  }

  .genetics-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

    .info-card {
      background-color: #ffffff;
      padding: 30px;
      border-radius: 15px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-5px);
      }

      .info-icon {
        font-size: 3rem;
        color: #bd692d;
        margin-bottom: 15px;
      }

      h3 {
        font-size: 1.8rem;
        color: #23283b;
        margin-bottom: 10px;
      }

      p {
        font-size: 1.1rem;
        color: #555;
        line-height: 1.6;
      }
    }
  }
}


@media (max-width: 1024px) {
  .second-section, .fourth-section {
    .content-wrapper {
      flex-direction: column;
      align-items: center;
    }
  }

  .second-section {
    .image-gallery, .species-info {
      width: 100%;
      max-width: 100%;
      margin: 0 0 40px 0;
    }
  }

  .fourth-section {
    .calculator-preview, .genetics-info {
      width: 100%;
      max-width: 600px;
      margin-bottom: 40px;
    }
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding: 120px 20px 60px;

    .hero-content {
      text-align: center;

      .hero-title {
        font-size: 2.5rem;
      }

      .hero-subtitle {
        font-size: 1.2rem;
      }
    }

    .image-showcase {
      width: 100%;
      max-width: none;
      height: auto;
      padding-bottom: 56.25%; 
    }
  }

  .second-section, .third-section, .fourth-section {
    padding: 60px 20px 40px;

    .section-title {
      font-size: 2.5rem;
    }
  }

  .second-section {
    .species-info {
      h2 {
        font-size: 1.8rem;
      }

      ul {
        columns: 1;
      }
    }
  }

  .fourth-section {
    .content-wrapper {
      flex-direction: column;
    }
  }
}

@media (max-width: 480px) {
  .hero-section {
    padding: 150px 20px 40px;

    .hero-title {
      font-size: 2rem;
    }

    .hero-subtitle {
            font-size: 1rem;
    }
  }

  .second-section, .third-section, .fourth-section {
    padding: 40px 20px 30px;

    .section-title {
      font-size: 2rem;
      margin-bottom: 20px;
    }
  }

  .second-section {
    .image-gallery {
      .main-image-container {
        height: 200px;
      }

      .thumbnail-container {
        .thumbnail {
          width: 60px;
          height: 60px;
        }
      }
    }

    .species-info {
      h2 {
        font-size: 1.5rem;
      }

      .description {
        font-size: 0.9rem;
      }

      ul li {
        font-size: 0.9rem;
      }

      .learn-more-button {
        font-size: 0.9rem;
        padding: 10px 20px;
      }
    }
  }

  .third-section {
    .gecko-slider-card {
      .card-title-text {
                font-size: 16px;
      }

      .card-price {
        font-size: 14px;
      }

      .card-actions {
        .btn {
          font-size: 12px;
          padding: 6px;
        }
      }
    }

    .view-all-btn {
      font-size: 1rem;
      padding: 12px 24px;
    }
  }

  .fourth-section {
    .calculator-preview {
      padding: 20px;

      .calculator-icon {
        font-size: 3rem;
      }

      h2 {
        font-size: 1.5rem;
      }

      p {
        font-size: 0.9rem;
      }

      .calculator-btn {
        font-size: 0.9rem;
        padding: 10px 20px;
      }
    }

    .genetics-info {
      .info-card {
        padding: 20px;

        .info-icon {
          font-size: 2.5rem;
        }

        h3 {
          font-size: 1.3rem;
        }

        p {
          font-size: 0.9rem;
        }
      }
    }
  }
}

.fifth-section {
  background-color: #23283b;
  padding: 80px 50px 100px;
  text-align: center;
  position: relative;
  z-index: 1;
  color: white;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 800'%3E%3Cg fill-opacity='0.2'%3E%3Cpath fill='%23bd692d' d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath fill='%23eb8e04' d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath fill='%23fbbc48' d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath fill='%23ff6b6b' d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath fill='%23bd692d' d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
    opacity: 0.2;
    z-index: -1;
    animation: rotateBrushStroke 60s linear infinite;
  }

  .section-title {
    font-size: 3rem;
    margin-bottom: 40px;
    color: white;
    font-weight: 700;
  }

  .content-wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    gap: 30px;
    @media (min-width: 1024px) {
      flex-wrap: nowrap;
    }
  }

  .feature {
    flex: 1;
    min-width: 250px;
    max-width: 350px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    color: #23283b;
    @media (min-width: 1024px) {
      min-width: 200px;
    }

    &:hover {
      transform: translateY(-10px);
    }

    .feature-icon {
      font-size: 3rem;
      margin-bottom: 20px;
      color: #bd692d;
    }

    h3 {
      font-size: 1.5rem;
      margin-bottom: 15px;
      color: #bd692d;
    }

    p {
      font-size: 1rem;
      color: #555;
      line-height: 1.6;
    }
  }
}

.red-text {
  color: #ff4d4d;
  font-weight: 700;
}

.shop-now-button {
  @extend .button;
  margin-top: 30px;
}

.button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #bd692d;
  color: white;
  text-decoration: none;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(189, 105, 45, 0.3);

  &:hover {
    background-color: #af5210;
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(189, 105, 45, 0.4);
  }

  @media (max-width: 768px) {
    padding: 12px 24px;
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}

.hero-section::before,
.third-section::before,
.fifth-section::before {
  opacity: 0.2;
}

.fourth-section {
  background-color: #ffffff;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 800'%3E%3Cg fill-opacity='0.3'%3E%3Cpath fill='%2323283b' d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath fill='%23343b56' d='M-31 229L237 261 390 382 603 494 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath fill='%23454e71' d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath fill='%2357628c' d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath fill='%236976a7' d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
    opacity: 0.25;
    z-index: -1;
    animation: rotateBrushStroke 60s linear infinite;
  }

  .section-title {
    font-size: 3rem;
    margin-bottom: 40px;
    color: #23283b;
    font-weight: 700;
  }

  .content-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
  }
}