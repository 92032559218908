/* app.scss */

body {
  background-color: #ffffff !important;
  color: #000000 !important;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #ffffff !important;
    color: #000000 !important;
  }
}

.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  position: relative;
  z-index: 1;
}

.with-background-effect {
  position: relative;
  
  &::before {
    content: '';
    position: fixed;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 800'%3E%3Cg fill-opacity='0.3'%3E%3Cpath fill='%2323283b' d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath fill='%23343b56' d='M-31 229L237 261 390 382 603 494 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath fill='%23454e71' d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath fill='%2357628c' d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath fill='%236976a7' d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
    background-attachment: fixed;
    opacity: 0.25;
    z-index: -1;
    animation: rotateBrushStroke 60s linear infinite;
  }
}

@keyframes rotateBrushStroke {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Media query for mobile screens
@media (max-width: 768px) {
  .with-background-effect {
    &::before {
      opacity: .3; // Reduce opacity on mobile for better readability
    }
  }
}