.event-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

.event-popup {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 30px;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  position: relative;
  animation: scaleIn 0.3s ease-out;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #23283b;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #bd692d;
  }
}

.popup-content {
  text-align: center;

  .gift-icon {
    font-size: 3rem;
    color: #bd692d;
    margin-bottom: 20px;
    animation: bounce 2s infinite;
  }

  h2 {
    font-size: 2rem;
    color: #23283b;
    margin-bottom: 15px;
  }

  p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
  }

  .event-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #bd692d;
    color: white;
    text-decoration: none;
    border-radius: 25px;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
      background-color: #af5210;
      transform: translateY(-3px);
    }
  }

  .dont-show-again {
    display: block;
    margin-top: 10px;
    background: none;
    border: none;
    color: #666;
    font-size: 0.8rem;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: #bd692d;
    }
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from { transform: scale(0.9); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-20px); }
  60% { transform: translateY(-10px); }
}

@media (max-width: 480px) {
  .event-popup-overlay {
    align-items: flex-start;
    padding-top: 50%;
  }

  .event-popup {
    padding: 20px;
  }

  .popup-content {
    h2 {
      font-size: 1.5rem;
    }

    p {
      font-size: 0.9rem;
    }

    .event-link {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  }
}