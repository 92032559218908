@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

.contact-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: white;
  padding: 210px 50px 100px;
  font-family: 'Poppins', sans-serif;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 800'%3E%3Cg fill-opacity='0.3'%3E%3Cpath fill='%2323283b' d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath fill='%23343b56' d='M-31 229L237 261 390 382 603 494 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath fill='%23454e71' d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath fill='%2357628c' d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath fill='%236976a7' d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
    opacity: 0.25;
    z-index: -1;
    animation: rotateBrushStroke 60s linear infinite;
  }
}

@keyframes rotateBrushStroke {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  
  @media (min-width: 992px) {
    flex-direction: row;
  }
}

.info-section {
  flex: 1;
  background: #ffffff;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
}

.title {
  font-size: 36px;
  font-weight: 700;
  color: #23283b;
  text-align: center;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 18px;
  color: #666;
  text-align: center;
  margin-bottom: 30px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .input-group {
    display: flex;
    flex-direction: column;
    gap: 5px;

    label {
      font-size: 14px;
      font-weight: 600;
      color: #23283b;
    }

    input, textarea {
      width: 100%;
      padding: 12px;
      border: 2px solid #e0e0e0;
      border-radius: 8px;
      font-size: 16px;
      transition: border-color 0.3s ease;

      &:focus {
        outline: none;
        border-color: #23283b;
      }
    }

    textarea {
      height: 150px;
      resize: vertical;
    }
  }
}

.send-button {
  align-self: flex-start;
  padding: 12px 30px;
  background-color: #23283b;
  color: #ffffff;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;

  span {
    position: relative;
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #bd692d;
    transition: all 0.3s ease;
  }

  &:hover::before {
    left: 0;
  }

  &:hover {
    box-shadow: 0 5px 15px rgba(35, 40, 59, 0.3);
  }
}

.success-message {
  margin-top: 20px;
  padding: 15px;
  background-color: #28a745;
  color: #ffffff;
  border-radius: 8px;
  text-align: center;
  font-weight: 600;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.additional-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.faq-section {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 24px;
    color: #23283b;
    margin-bottom: 20px;
  }

  .faq-list {
    .faq-item {
      margin-bottom: 15px;
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 15px;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      summary {
        font-weight: 600;
        cursor: pointer;
        padding: 10px 0;
        transition: color 0.3s ease;

        &:hover {
          color: #bd692d;
        }
      }

      p {
        padding: 10px 0;
        color: #666;
      }
    }
  }
}

.newsletter-signup {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 24px;
    color: #23283b;
    margin-bottom: 10px;
  }

  p {
    color: #666;
    margin-bottom: 20px;
  }

  form {
    display: flex;
    gap: 10px;

    input {
      flex: 1;
      padding: 10px;
      border: 2px solid #e0e0e0;
      border-radius: 5px;
      font-size: 16px;

      &:focus {
        outline: none;
        border-color: #23283b;
      }
    }

    button {
      padding: 10px 20px;
      background-color: #23283b;
      color: #ffffff;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #bd692d;
      }
    }
  }
}

.map-container {
  flex: 1;
  min-height: 300px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  
  // Ensure the map takes up the full height of its container
  & > div {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

@media (max-width: 1200px) {
  .contact-container {
    padding-top: 200px;
  }
}

@media (max-width: 991px) {
  .info-section, .additional-info {
    width: 100%;
  }

  .map-container {
    height: 400px;
  }
}

@media (max-width: 768px) {
  .contact-container {
    padding-top: 180px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .title {
    font-size: 28px;
  }

  .subtitle {
    font-size: 16px;
  }

  .send-button {
    align-self: center;
    width: 100%;
  }

  .newsletter-signup form {
    flex-direction: column;
  }

  .map-container {
    height: 300px;
  }
}

@media (max-width: 480px) {
  .contact-container {
    padding-top: 140px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .info-section, .additional-info > div {
    padding: 20px;
  }

  .map-container {
    height: 250px;
  }
}