.blog-post-container {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 200px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: Arial, sans-serif;

  .blog-post-title {
    font-size: 2.5rem;
    color: #bd692d;
    margin-bottom: 10px;
  }

  .blog-post-date {
    font-size: 1rem;
    color: #666;
    margin-bottom: 30px;
  }

  .blog-post-content {
    h2 {
      font-size: 1.8rem;
      color: #23283b;
      margin-top: 30px;
      margin-bottom: 15px;
    }

    p {
      font-size: 1.1rem;
      line-height: 1.6;
      margin-bottom: 15px;
    }

    ol, ul {
      margin-bottom: 20px;
      padding-left: 20px;

      li {
        font-size: 1.1rem;
        line-height: 1.6;
        margin-bottom: 10px;
      }
    }

    .image-container {
      margin-bottom: 20px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;

      &.rolling-icon-examples {
        .image-wrapper {
          flex: 1;
          max-width: calc(50% - 10px);
          aspect-ratio: 1 / 1;
          overflow: hidden;

          .blog-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      &.giveaway-image {
        flex-direction: column;
        align-items: center;

        .image-wrapper {
          width: 100%;
          max-width: 100%;
          position: relative;
          overflow: hidden;
          aspect-ratio: 3000 / 3242;
          
          .blog-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
          }

          .image-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transition: opacity 0.3s ease;

            span {
              color: white;
              font-size: 18px;
              font-weight: bold;
            }
          }

          &:hover .image-overlay {
            opacity: 1;
          }
        }

        .image-caption {
          text-align: center;
          font-style: italic;
          margin-top: 10px;
          width: 100%;
        }
      }
    }

    .image-description {
      font-size: 0.9rem;
      color: #666;
      text-align: center;
      margin-bottom: 20px;
    }

    .leaderboard-link {
      display: inline-block;
      background-color: #bd692d;
      color: white;
      padding: 10px 20px;
      border-radius: 5px;
      text-decoration: none;
      font-weight: bold;
      margin-top: 10px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken(#bd692d, 10%);
      }
    }

    .event-details {
      margin-top: 30px;
      padding: 20px;
      background-color: #f5f5f5;
      border-radius: 5px;

      p {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .modal {
    position: fixed;
    padding-top: 100px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10001;

    .modal-content {
      position: relative;
      width: 90%;
      height: 90%;
      max-width: 1200px;
      max-height: calc(100vh - 60px);
      margin: 30px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;

      .modal-image-container {
        position: relative;
        max-width: 100%;
        max-height: 100%;
      }

      .modal-image {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }

      .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        border: none;
        color: white;
        font-size: 2rem;
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        z-index: 10002;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: rgba(0, 0, 0, 0.8);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .blog-post-container {
    padding-top: 150px;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;

    .blog-post-title {
      font-size: 2rem;
    }

    .blog-post-content {
      h2 {
        font-size: 1.5rem;
      }

      p, ol li, ul li {
        font-size: 1rem;
      }

      .image-container {
        &.rolling-icon-examples {
          flex-direction: column;

          .image-wrapper {
            max-width: 100%;
            margin-bottom: 10px;
          }
        }

        &.giveaway-image {
          .image-wrapper {
            max-width: 100%;
          }
        }
      }
    }

    .modal {
      .modal-content {
        width: 95%;
        height: 95%;
        margin: 15px auto;
        max-height: calc(100vh - 30px);
      }
    }
  }
}

@media (max-width: 480px) {
  .blog-post-container {
    .modal {
      .modal-content {
        width: 100%;
        height: 100%;
        margin: 0;
        max-height: 100vh;
      }
    }
  }
}