.leaderboard-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 200px 20px 200px;
  font-family: Arial, sans-serif;

  .leaderboard-title {
    font-size: 2.5rem;
    color: #bd692d;
    margin-bottom: 30px;
    text-align: center;
  }

  .leaderboard-info {
    text-align: center;
    margin-bottom: 20px;

    p {
      margin-bottom: 10px;
    }

    .user-stats {
      font-size: 1.1rem;
      color: #bd692d;
      margin-top: 10px;
      font-weight: bold;

      svg {
        margin-right: 5px;
      }
    }

    .days-remaining {
      font-size: 1.2rem;
      color: #bd692d;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  .leaderboard-table {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    .leaderboard-header,
    .leaderboard-row {
      display: flex;
      padding: 15px;
      border-bottom: 1px solid #e0e0e0;

      &:last-child {
        border-bottom: none;
      }

      .rank,
      .name,
      .points {
        flex: 1;
      }

      .rank {
        flex: 0 0 80px;
        text-align: center;
      }

      .points {
        text-align: right;
      }
    }

    .leaderboard-header {
      background-color: #f5f5f5;
      font-weight: bold;
      color: #23283b;
    }

    .leaderboard-row {
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #f9f9f9;
      }

      .trophy {
        margin-right: 5px;

        &.gold {
          color: #ffd700;
        }

        &.silver {
          color: #c0c0c0;
        }

        &.bronze {
          color: #cd7f32;
        }
      }

      .points-icon {
        color: #bd692d;
        margin-right: 5px;
      }

      &.eligible {
        background-color: #e8f5e9;
      }

      &.current-user {
        background-color: #fff3e0;
        font-weight: bold;
      }
    }
  }

  .loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5rem;
    color: #bd692d;
    margin-top: 20px;

    svg {
      margin-bottom: 10px;
    }
  }

  .last-updated {
    text-align: center;
    font-size: 0.9rem;
    color: #666;
    margin-top: 20px;
  }

  .gecko-visible {
    font-size: 1.2rem;
    color: #ff9800;
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    animation: pulse 2s infinite;

    svg {
      margin-right: 5px;
    }
  }

  .next-respawn-time {
    font-size: 1rem;
    color: #bd692d;
    margin-top: 10px;
    text-align: center;

    svg {
      margin-right: 5px;
    }
  }

  .error-message {
    color: #d32f2f;
    text-align: center;
    font-size: 1.2rem;
    margin-top: 20px;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
}

@media (max-width: 768px) {
  .leaderboard-container {
    padding-top: 150px;
    padding-bottom: 30px;

    .leaderboard-title {
      font-size: 2rem;
    }

    .leaderboard-table {
      .leaderboard-header,
      .leaderboard-row {
        font-size: 0.9rem;
        padding: 10px;

        .rank {
          flex: 0 0 60px;
        }
      }
    }
  }
}