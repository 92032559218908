.google-signin-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.875rem;
  background-color: #ffffff;
  color: #757575;
  border: 1px solid #dadce0;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f8f9fa;
    box-shadow: 0 1px 3px rgba(60,64,67,0.3);
    border-color: #4285F4; // Google blue color on hover
  }

  svg {
    margin-right: 0.75rem;
    width: 18px;
    height: 18px;
  }
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ccc;
  }

  span {
    padding: 0 10px;
    color: #777;
    font-size: 14px;
  }
}