.cart-page {
  padding-top: 200px;
  padding-bottom: 40px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.cart-container {
  width: 100%;
  max-width: 800px;
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cart-title {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #23283b;
  text-align: center;
}

.empty-cart {
  text-align: center;
  font-size: 1.2rem;
  color: #666;

  .continue-shopping {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #23283b;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #bd692d;
    }
  }
}

.cart-items {
  .cart-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #eee;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f9f9f9;
    }

    .item-link {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: inherit;
      flex-grow: 1;
      max-width: calc(100% - 120px);
    }

    .item-image {
      width: 80px;
      height: 80px;
      object-fit: cover;
      margin-right: 1rem;
      border-radius: 4px;
    }

    .item-details {
      flex-grow: 1;
      min-width: 0;

      h3 {
        font-size: 1.2rem;
        margin: 0 0 0.5rem;
        color: #23283b;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .item-price {
        font-size: 1.1rem;
        color: #bd692d;
        font-weight: bold;
      }
    }

    .item-actions {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    .remove-button {
      background-color: #ff4d4d;
      border: none;
      color: white;
      cursor: pointer;
      font-size: 1rem;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken(#ff4d4d, 10%);
      }

      .remove-text {
        margin-left: 0.5rem;
      }
    }
  }
}

.cart-summary {
  margin-top: 2rem;
  border-top: 2px solid #eee;
  padding-top: 1rem;

  .cart-total {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #23283b;
    text-align: right;
  }

  .cart-actions {
    display: flex;
    justify-content: space-between;
  }

  .checkout-button,
  .clear-cart-button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .checkout-button {
    background-color: #23283b;
    color: white;

    &:hover {
      background-color: #bd692d;
    }
  }

  .clear-cart-button {
    background-color: #e0e0e0;
    color: #23283b;

    &:hover {
      background-color: darken(#e0e0e0, 10%);
    }
  }
}

@media (max-width: 600px) {
  .cart-page {
    padding-top: 150px;
  }

  .cart-container {
    padding: 1rem;
  }

  .cart-items .cart-item {
    flex-wrap: wrap;

    .item-link {
      width: 100%;
      max-width: 100%;
      margin-bottom: 0.5rem;
    }

    .item-image {
      width: 60px;
      height: 60px;
      min-width: 60px;
    }

    .item-actions {
      width: 100%;
      justify-content: flex-end;
      margin-top: 0.5rem;
    }

    .remove-button {
      width: 100%;
      text-align: center;
    }
  }

  .cart-summary .cart-actions {
    flex-direction: column;
    gap: 1rem;

    .checkout-button,
    .clear-cart-button {
      width: 100%;
    }
  }
}