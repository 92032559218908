@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

.breeder-map {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: 'Poppins', Arial, sans-serif;

  .section-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #23283b;
    font-weight: 700;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);

    @media (min-width: 768px) {
      font-size: 3rem;
      margin-bottom: 40px;
    }
  }

  .map-and-panel-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 400px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    position: relative;

    &.mobile {
      height: 100%;
    }

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .map-wrapper {
    flex: 1;
    position: relative;
    height: 100%;
    min-height: 400px;

    @media (min-width: 768px) {
      min-height: 400px;
    }

    &.adding-location {
      cursor: crosshair;
    }

    .floating-controls {
      position: absolute;
      top: 280px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1000;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      width: 90%;
      max-width: 400px;

      @media (max-width: 768px) {
        top: 160px;
        width: 95%;
        max-width: 100%;
      }

      @media (min-width: 769px) and (max-width: 1024px) {
        top: 170px;
      }

      @media (min-width: 1025px) {
        top: 220px;
      }

      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: center;
        max-width: 800px;
      }

      .search-container {
        position: relative;
        width: 100%;
        max-width: 400px;

        @media (max-width: 767px) {
          max-width: 100%;
        }

        input {
          width: 100%;
          padding: 10px 40px 10px 16px;
          border-radius: 25px;
          border: 2px solid #23283b;
          font-size: 14px;
          outline: none;
          transition: all 0.3s ease;
          text-overflow: ellipsis;
          height: 40px;

          &:focus {
            box-shadow: 0 0 0 3px rgba(189, 105, 45, 0.2);
            border-color: #bd692d;
          }
        }

        .search-button {
          position: absolute;
          right: 30px;
          top: 50%;
          transform: translateY(-50%);
          background-color: transparent;
          color: #23283b;
          border: none;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.3s ease;
          font-size: 16px;

          &:hover {
            color: #bd692d;
          }
        }

        .search-dropdown {
          position: absolute;
          top: calc(100% + 5px);
          left: 0;
          right: 0;
          background-color: white;
          border: 2px solid #23283b;
          border-radius: 15px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          z-index: 1000;
          overflow: hidden;
          opacity: 0;
          transform: translateY(-10px);
          transition: all 0.3s ease;
          pointer-events: none;
          max-height: 0;

          &.open {
            opacity: 1;
            transform: translateY(0);
            pointer-events: auto;
            max-height: 200px;
          }

          .show-breeders-around-me-button {
            width: 100%;
            padding: 12px 20px;
            text-align: left;
            background: none;
            border: none;
            cursor: pointer;
            transition: all 0.3s ease;
            font-size: 14px;
            color: #23283b;
            display: flex;
            align-items: center;

            svg {
              margin-right: 10px;
              font-size: 18px;
            }

            &:hover {
              background-color: rgba(189, 105, 45, 0.1);
              color: #bd692d;
            }
          }
        }
      }

      .add-location-button {
        width: 100%;
        max-width: 400px;
        padding: 10px 16px;
        background-color: #23283b;
        color: white;
        border: none;
        border-radius: 25px;
        cursor: pointer;
        font-size: 14px;
        transition: background-color 0.3s;
        text-align: center;

        @media (max-width: 767px) {
          padding: 8px 12px;
          font-size: 12px;
        }

        &:hover {
          background-color: #bd692d;
        }

        &.active {
          background-color: #bd692d;

          &:hover {
            background-color: #a55a25;
          }
        }

        @media (min-width: 768px) {
          width: auto;
        }
      }
    }
  }

  .side-panel {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.15);
    padding: 20px;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform: translateY(0);
    opacity: 1;
    max-height: 50%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    &.mobile {
      max-height: 60%;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    @media (min-width: 768px) {
      position: absolute;
      top: 170px;
      right: 0;
      left: auto;
      width: 280px;
      height: calc(100% - 170px);
      max-height: none;
      box-shadow: -4px 0 20px rgba(0, 0, 0, 0.15);
      transform: translateX(0);
      border-radius: 0;
    }

    .drag-handle {
      width: 40px;
      height: 4px;
      background-color: #ccc;
      border-radius: 2px;
      margin: 0 auto 10px;
      display: block;

      @media (min-width: 768px) {
        display: none;
      }
    }

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 20px;
      cursor: pointer;
      color: #23283b;
      transition: color 0.3s, transform 0.3s;

      &:hover {
        color: #bd692d;
        transform: rotate(90deg);
      }
    }

    .breeder-header {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      padding-bottom: 10px;
      border-bottom: 2px solid #bd692d;

      .breeder-logo {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 10px;
      }

      h3 {
        font-size: 22px;
        color: #23283b;
        margin: 0;
      }
    }

    .info-item {
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      strong {
        font-size: 14px;
        color: #555;
        margin-bottom: 5px;
      }

      span, input {
        font-size: 16px;
        color: #23283b;
      }

      input {
        width: 100%;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
      }

      ul {
        list-style-type: none;
        padding-left: 0;
        margin: 0;
      }

      li {
        margin-bottom: 5px;
      }
    }

    .species-input {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      input {
        flex-grow: 1;
        margin-right: 10px;
      }

      button {
        background-color: #f0f2f5;
        border: none;
        color: #d32f2f;
        cursor: pointer;
        font-size: 14px;
        padding: 5px 8px;
        border-radius: 4px;
        transition: background-color 0.3s, color 0.3s;

        &:hover {
          background-color: #ffcdd2;
        }
      }
    }

    .add-species-button {
      border: none;
      color: #a55a25;
      cursor: pointer;
      font-size: 14px;
      padding: 8px 12px;
      border-radius: 4px;
      margin-top: 10px;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: #a55a25;
        color: white;
      }
    }

    .button-group {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
    }

    .edit-button, .remove-button, .save-button {
      display: block;
      width: 100%;
      padding: 10px;
      background-color: #bd692d;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s;

      &:hover {
        background-color: #a55a25;
      }
    }

    .edit-button {
      margin-right: 5px;
    }

    .remove-button {
      margin-left: 5px;
    }

    .file-input-label {
      display: inline-block;
      padding: 10px 15px;
      background-color: #f0f2f5;
      color: #23283b;
      border: 1px solid #ccc;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #e4e6e9;
      }
    }

    .file-input {
      display: none;
    }

    .logo-preview {
      max-width: 100%;
      height: auto;
      margin-top: 10px;
      border-radius: 4px;
    }

    .form-input {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 14px;
      margin-bottom: 10px;
    }

    .link-input {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
  
      input {
        flex-grow: 1;
        margin-right: 10px;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 14px;
      }
  
      .btn-icon {
        background-color: #f0f2f5;
        border: none;
        color: #d32f2f;
        cursor: pointer;
        font-size: 14px;
        padding: 8px;
        border-radius: 4px;
        transition:  background-color 0.3s, color 0.3s;
  
        &:hover {
          background-color: #ffcdd2;
        }
      }
    }
  
    .add-link-button {
      display: inline-block;
      background-color: #f0f2f5;
      color: #bd692d;
      border: none;
      cursor: pointer;
      font-size: 14px;
      padding: 8px 12px;
      border-radius: 4px;
      margin-top: 10px;
      transition: background-color 0.3s, color 0.3s;
  
      &:hover {
        background-color: #bd692d;
        color: white;
      }
    }
  
    .links-list {
      list-style-type: none;
      padding-left: 0;
      margin: 0;
  
      li {
        margin-bottom: 8px;
  
        a {
          display: inline-block;
          color: #bd692d;
          text-decoration: none;
          transition: color 0.3s;
          padding: 6px 10px;
          background-color: #f0f2f5;
          border-radius: 4px;
  
          &:hover {
            color: #a55a25;
            background-color: #e4e6e9;
          }
        }
      }
    }
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
    overflow-y: auto;
    padding: 20px;

    @media (max-width: 600px) {
      padding: 10px;
    }
  }

  .modal-content {
    background-color: white;
    border-radius: 12px;
    width: 90%;
    max-width: 600px;
    max-height: 70vh;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    margin-top: 180px;

    @media (max-width: 600px) {
      width: 95%;
      max-height: 90vh;
      border-radius: 8px;
      margin-top: 200px;
    }

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 20px;
      cursor: pointer;
      color: #23283b;
      transition: color 0.3s, transform 0.3s;
      z-index: 1002;

      &:hover {
        color: #bd692d;
        transform: rotate(90deg);
      }
    }

    .modal-inner-content {
      padding: 30px 20px;

      @media (max-width: 600px) {
        padding: 20px 15px;
      }

      h3 {
        font-size: 22px;
        color: #23283b;
        margin-bottom: 20px;
        text-align: center;

        @media (max-width: 600px) {
          font-size: 20px;
          margin-bottom: 15px;
        }
      }
    }

    .add-location-form {
      .form-group {
        margin-bottom: 1rem;

        label {
          display: block;
          margin-bottom: 0.5rem;
          font-weight: 600;
        }

        .form-input {
          width: 100%;
          padding: 0.5rem;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 16px;

          @media (max-width: 600px) {
            font-size: 14px;
          }
        }

        .species-input,
        .link-input {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;

          .form-input {
            flex-grow: 1;
            margin-right: 0.5rem;
          }

          .btn-icon {
            padding: 0.5rem;
            font-size: 1rem;
            line-height: 1;
            border: none;
            background: none;
            cursor: pointer;
            transition: color 0.3s ease;

            &.btn-remove {
              color: #d32f2f;

              &:hover {
                color: #b71c1c;
              }
            }
          }
        }

        .btn-add {
          display: inline-flex;
          align-items: center;
          padding: 0.5rem 1rem;
          font-size: 0.875rem;
          line-height: 1;
          color: #bd692d;
          background-color: transparent;
          border: none;
          cursor: pointer;
          transition: background-color 0.3s ease, color 0.3s ease;

          &:hover {
            background-color: rgba(189, 105, 45, 0.1);
          }

          .fa-plus {
            margin-right: 0.5rem;
          }
        }
      }

      .file-input-label {
        display: inline-block;
        padding: 0.5rem 1rem;
        background-color: #f0f2f5;
        color: #23283b;
        border: 1px solid #ccc;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #e4e6e9;
        }
      }

      .file-input {
        display: none;
      }

      .logo-preview {
        max-width: 100%;
        height: auto;
        margin-top: 10px;
        border-radius: 4px;
      }

      .button-group {
        margin-top: 1.5rem;
      }

      .btn-submit {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0.75rem 1rem;
        font-size: 1rem;
        font-weight: 600;
        color: #ffffff;
        background-color: #bd692d;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #a55a25;
        }

        .fa-check {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.marker-wrapper {
  position: relative;
  width: 40px;
  height: 40px;

  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
}

.pulse {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(189, 105, 45, 0.4);
  opacity: 0;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.custom-marker {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #bd692d;
  border: 2px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
  z-index: 1;
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  animation: pulse 1.5s infinite;

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.cluster-marker {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #bd692d;
  border: 2px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;

  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }
}

.cluster-count {
  animation: pulse 1.5s infinite;
}

.breeder-map__loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 18px;
  color: #333;

  svg {
    margin-bottom: 10px;
  }
}