.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 200px;
  background-color: #23283b;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  transition: padding 0.5s ease;

  .logo-link {
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;

    .logo {
      width: 50px;
      height: auto;
      margin-right: 10px;
    }

    .company-name {
      font-size: 1.5rem;
      font-weight: bold;
      color: #bd692d;
    }
  }

  .nav-links {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    background-color: #23283b;

    .nav-link-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .nav-link {
      text-decoration: none;
      color: white;
      position: relative;
      font-size: 1.2rem;
      font-weight: bold;
      transition: all 0.3s;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      border-radius: 5px;

      &:hover {
        color: #bd692d;
        background-color: rgba(255, 255, 255, 0.1);
      }

      &.active {
        color: #bd692d;
        background-color: rgba(255, 255, 255, 0.1);

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -3px;
          height: 2px;
          background-color: #bd692d;
          width: 100%;
          transform: scaleX(0);
          transition: transform 0.3s ease;
        }

        &:hover::after {
          transform: scaleX(1);
        }
      }
    }

    .tools-dropdown {
      position: relative;

      .nav-link {
        display: flex;
        align-items: center;
        gap: 0;
        cursor: pointer;
        padding-right: 6px;
      }

      .dropdown-icon {
        transition: transform 0.3s ease;
        margin-left: 2px;

        &.open {
          transform: rotate(180deg);
        }
      }

      .tools-dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #23283b;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        z-index: 1000;
        min-width: 150px;
        padding: 5px 0;
        display: none;

        .nav-link-container {
          width: 100%;
          justify-content: flex-start;
        }

        .nav-link {
          padding: 10px 15px;
          white-space: nowrap;
          display: flex;
          width: 100%;
          justify-content: flex-start;
          text-align: left;

          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
        }
      }

      &.open {
        .tools-dropdown-menu {
          display: block;
        }
      }
    }

    @media (max-width: 768px) {
      display: none;
      flex-direction: column;
      gap: 0;
      position: fixed;
      top: 0;
      right: -280px;
      width: 280px;
      height: 100vh;
      background-color: #2a3042;
      z-index: 1000;
      padding: 100px 0 20px;
      overflow-y: auto;
      transition: right 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
      box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);

      &.open {
        display: flex;
        right: 0;
        box-shadow: -5px 0 15px rgba(0, 0, 0, 0.2);
      }

      .nav-link-container {
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }

      .nav-link {
        font-size: 1.2rem;
        padding: 15px 25px;
        width: 100%;
        justify-content: center;
        background-color: transparent;
        color: #ffffff;
        transition: all 0.3s ease;
        text-align: center;

        &:hover {
          color: #bd692d;
          background-color: rgba(189, 105, 45, 0.1);
        }

        &.active {
          color: #bd692d;
          background-color: rgba(189, 105, 45, 0.2);
          font-weight: bold;
        }
      }

      .tools-dropdown {
        width: 100%;
        flex-direction: column;
        align-items: center;

        .nav-link {
          width: 100%;
          justify-content: center;
          padding: 15px 25px;
        }

        .tools-dropdown-menu {
          position: static;
          box-shadow: none;
          background-color: rgba(255, 255, 255, 0.05);
          padding: 0;
          width: 100%;
          display: none;
          border-top: 1px solid rgba(255, 255, 255, 0.1);

          .nav-link-container {
            width: 100%;
            border-bottom: none;
          }

          .nav-link {
            padding: 12px 35px;
            justify-content: center;
            width: 100%;
            text-align: center;
            font-size: 1.1rem;
            color: #ffffff;

            &:hover {
              color: #bd692d;
              background-color: rgba(189, 105, 45, 0.1);
            }

            &.active {
              color: #bd692d;
              background-color: rgba(189, 105, 45, 0.2);
              font-weight: bold;
            }
          }
        }

        &.open {
          .tools-dropdown-menu {
            display: flex;
            flex-direction: column;
          }

          .dropdown-icon {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .menu-icons-container {
    display: flex;
    align-items: center;
    gap: 20px;

    .nav-icons {
      display: flex;
      align-items: center;

      .nav-icon-link {
        color: white;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
        transition: color 0.3s ease;

        &:hover {
          color: #bd692d;
        }

        &.cart-icon {
          position: relative;
          margin-left: 10px;
        }

        .icon {
          font-size: 1.8rem;
          color: white;
        }

        .cart-count {
          position: absolute;
          bottom: -5px;
          right: -5px;
          background-color: #d11717;
          color: white;
          font-size: 0.7rem;
          font-weight: bold;
          min-width: 16px;
          height: 16px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 3px;
        }
      }
      .user-email {
        color: white;
        font-size: 1rem;
        margin-right: 10px;
      }

      .sign-out-button {
        background-color: #bd692d;
        color: white;
        border: none;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: darken(#bd692d, 10%);
        }
      }
    }

    .hamburger {
      display: none;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 24px;
        height: 24px;
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
        z-index: 1001;

        span {
          width: 24px;
          height: 2px;
          background: #bd692d;
          border-radius: 10px;
          transition: all 0.3s linear;
          position: relative;
          transform-origin: 1px;
        }

        &.open {
          span:nth-child(1) {
            transform: rotate(45deg);
          }
          span:nth-child(2) {
            opacity: 0;
            transform: translateX(20px);
          }
          span:nth-child(3) {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  &.scrolled {
    padding: 10px 20px;
    transition: padding 0.5s ease;
  }

  &.unscrolled {
    padding: 40px 200px;
    transition: padding 0.5s ease;
  }

  &.open {
    padding: 40px 200px;
  }

  @media screen and (max-width: 1280px) {
    padding: 20px 100px;
    transition: padding 0.5s ease;

    &.scrolled {
      padding: 10px 50px;
    }

    &.unscrolled {
      padding: 20px 100px;
    }

    &.open {
      padding: 20px 100px;
    }

    .logo-link {
      .logo {
        width: 40px;
        margin-right: 8px;
      }
      .company-name {
        font-size: 1.3rem;
      }
    }

    .nav-links {
      gap: 15px;

      .nav-link {
        font-size: 1.1rem;
        padding: 5px 8px;
      }
    }

    .menu-icons-container {
      gap: 15px;

      .nav-icons {
        .nav-icon-link {
          font-size: 1.2rem;

          .icon {
            font-size: 1.5rem;
          }

          &.cart-icon {
            margin-left: 5px;

            .cart-count {
              font-size: 0.6rem;
              min-width: 14px;
              height: 14px;
              bottom: -3px;
              right: -3px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 20px 50px;
    transition: padding 0.5s ease;

    &.scrolled {
      padding: 10px 25px;
    }

    &.unscrolled {
      padding: 20px 50px;
    }

    &.open {
      padding: 20px 50px;

      .nav-links {
        right: 0;
      }
    }

    .menu-icons-container {
      .nav-icons {
        .nav-icon-link {
          font-size: 1.3rem;
          color: white;

          .icon {
            font-size: 1.6rem;
            color: #bd692d;
          }

          &.cart-icon {
            .cart-count {
              font-size: 0.65rem;
              min-width: 14px;
              height: 14px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding: 20px 50px;
    transition: padding 0.5s ease;

    &.scrolled {
      padding: 10px 20px;
    }

    &.unscrolled {
      padding: 20px 50px;
    }

    &.open {
      padding: 20px 50px;
    }

    .menu-icons-container {
      order: 2;
      gap: 10px;

      .nav-icons {
        gap: 10px;

        .nav-icon-link {
          font-size: 1.1rem;
          color: white;

          .icon {
            font-size: 1.4rem;
            color: #bd692d;
          }

          &.cart-icon {
            margin-left: 0;

            .cart-count {
              font-size: 0.6rem;
              min-width: 12px;
              height: 12px;
              bottom: -3px;
              right: -3px;
            }
          }
        }
      }

      .hamburger {
        width: 1.5rem;
        height: 1.5rem;

        span {
          width: 1.5rem;
          height: 0.18rem;
        }
      }
    }

    .logo-link {
      order: 1;
      .logo {
        width: 40px;
        margin-right: 5px;
      }
      .company-name {
        font-size: 1.2rem;
      }
    }

    .nav-links {
      order: 3;
    }
  }

  @media screen and (max-width: 320px) {
    padding: 20px 50px;
    transition: padding 0.5s ease;

    &.scrolled {
      padding: 10px 20px;
    }

    &.unscrolled {
      padding: 20px 50px;
    }

    &.open {
      padding: 20px 50px;
    }

    .menu-icons-container {
      order: 2;
      gap: 5px;

      .nav-icons {
        gap: 5px;

        .nav-icon-link {
          font-size: 0.9rem;
          color: white;

          .icon {
            font-size: 1.1rem;
            color: #bd692d;
          }

          &.cart-icon {
            margin-left: 3px;

            .cart-count {
              font-size: 0.5rem;
              min-width: 10px;
              height:  10px;
              bottom: -2px;
              right: -2px;
            }
          }
        
        }
      }

      .hamburger {
        width: 1.2rem;
        height: 1.2rem;

        span {
          width: 1.2rem;
          height: 0.15rem;
        }
      }
    }

    .logo-link {
      order: 1;
      .logo {
        width: 20px;
        margin-right: 5px;
      }
      .company-name {
        font-size: 0.9rem;
      }
    }

    .nav-links {
      order: 3;
      padding: 120px 15px 15px;

      &.open {
        display: flex;
      }

      .nav-link-container {
        width: 100%;
      }

      .nav-link {
        font-size: 1.2rem;
        padding: 12px;
        width: 100%;
        justify-content: center;
        background-color: transparent;
        color: white;

        &:hover {
          color: #bd692d;
          background-color: rgba(189, 105, 45, 0.2);
        }

        &.active {
          color: #bd692d;
          background-color: rgba(189, 105, 45, 0.2);
        }
      }
    }
  }
}

.nav-icons {
  .nav-icon-link {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    color: white;
    font-size: 1.5rem;
    transition: color 0.3s ease;

    &:hover {
      color: #bd692d;
    }

    .icon {
      font-size: 1.8rem;
      color: white;
    }
  }
}

.navbar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  &.open {
    opacity: 1;
    visibility: visible;
  }
}